<template functional>
  <li :class="['checkout-summary-separator', data.class, data.staticClass]">
    <hr/>
  </li>
</template>

<script>
export default {
  name: 'CheckoutSummarySeparator'
}
</script>

<style lang="scss">
$checkoutsummary-separator-margin-y: $spacer * 0.5 !default;

.checkout-summary-separator {
  hr {
    margin-top: $checkoutsummary-separator-margin-y;
    margin-bottom: $checkoutsummary-separator-margin-y;
  }
}
</style>
