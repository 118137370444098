<template>
  <div class="basket-item">
    <div class="area-separator"/>

    <div class="area-image">
      <a :href="productUrl">
        <img v-if="product.image" :src="product.image" :alt="product.name"/>
        <c-placeholder-image v-else ratio="4x3" size="100"/>
      </a>
    </div>

    <div v-if="$root.isMobile" class="area-actions">
      <div class="item-actions">
        <b-link @click="configureItem"><b-icon icon="pen"/></b-link>
        <b-link @click="deleteItem"><b-icon icon="trash"/></b-link>
      </div>
    </div>

    <div class="area-description">
      <p><a :href="productUrl"><span class="item-name">{{ product.model }}</span> {{ product.name }}</a></p>
    </div>

    <template v-if="$root.isMobile">
        <div v-if="configuration.additionalConfigurations.dateStart && configuration.additionalConfigurations.dateEnd" class="area-additional-configs">
            <span>{{ $t(`${tPath}.deliverytimeslot`) }} {{configuration.additionalConfigurations.dateStart | formatDate}} - {{configuration.additionalConfigurations.dateEnd | formatDate}}</span>
          </div>
        <div v-if="$root.isMobile" class="area-configuration">
          <p class="item-configuration">
              <span v-if="product.bundleCount">{{ $t(`${tPath}.bundlecount`, { count: product.bundleCount  }) }}</span>
              <span>{{$t(`${tPath}.articlenumber`)}} {{product.articleNumber ? product.articleNumber : product.id }}</span>
          </p>
        </div>
    </template>

    <div class="area-quantity">
      <b-row v-if="$root.isMobile" no-gutter align-h="between" align-v="center">
        <b-col cols="auto">{{ $t(`${tPath}.items.header.quantity`) }}</b-col>
        <b-col cols="auto">
          <control-spinbutton
            v-if="module.isEditable"
            :id="_uid + 'quantity'"
            v-model="itemQuantity"
            size="sm"
            class="mb-0"
            @input="updateItem"
          />

          <span v-else>{{ itemQuantity }}</span>
        </b-col>
      </b-row>

      <template v-else>
        <control-spinbutton
          v-if="module.isEditable"
          :id="_uid + 'quantity'"
          v-model="itemQuantity"
          size="sm"
          class="mb-0"
          :max="Number.MAX_SAFE_INTEGER"
          @input="updateItem"
        />

        <span v-else>{{ itemQuantity }}</span>
      </template>
    </div>

    <div class="area-price">
      <c-product-price :productId="product.id" :priceinfo="product.priceInfo" alginH="end" hideVatInfo inherit/>
    </div>

    <div class="area-totalprice">
      <c-product-price :productId="product.id" :priceinfo="product.priceInfo" :quantity="quantity" alginH="end" hideVatInfo inherit/>
    </div>

    <template v-if="!$root.isMobile">
      <div v-if="configuration.additionalConfigurations.dateStart && configuration.additionalConfigurations.dateEnd" class="area-additional-configs">
          <b-icon :id="'popover-additional-config-'+basketItemId" icon="b-info-circle"></b-icon>
          <b-popover :target="'popover-additional-config-'+basketItemId" triggers="hover focus">
            <strong>{{$t(`${tPath}.deliverytimeslot`)}}</strong><br>
            {{configuration.additionalConfigurations.dateStart | formatDate}} - {{configuration.additionalConfigurations.dateEnd | formatDate}}
          </b-popover>
      </div>
      <div class="area-configuration">
        <p class="item-configuration">
          <span v-if="product.bundleCount">{{ $t(`${tPath}.bundlecount`, { count: product.bundleCount  }) }}</span>
          <span>{{$t(`${tPath}.articlenumber`)}} {{product.articleNumber ? product.articleNumber : product.id }}</span>
        </p>

      </div>

      <div v-if="module.isEditable" class="area-actions">
        <div class="item-actions">
          <!-- <a href="#" @click="configureItem"><b-icon icon="pen"/></a> -->
          <a href="#" @click="deleteItem"><b-icon icon="trash"/></a>
        </div>
      </div>
    </template>

    <div class="area-separator"/>
  </div>
</template>

<script>
import { COMPONENT_BASKET_MODULES } from '@/constants'

import ControlSpinbutton from '@/components/form/ControlSpinbutton'

const BASKET_MODULES = Object.values(COMPONENT_BASKET_MODULES)
const DEFAULT_MODULENAME = (BASKET_MODULES.find(m => m.isDefault) || BASKET_MODULES[0]).name
const ALLOWED_MODULE_NAMES = BASKET_MODULES.map(m => m.name)

export default {
  name: 'BasketItem',
  components: {
    ControlSpinbutton
  },
  props: {
    moduleName: {
      type: String,
      default: DEFAULT_MODULENAME,
      validator: value => ALLOWED_MODULE_NAMES.includes(value)
    },
    basketItemId: {
      type: String,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    productType: {
      type: String,
      required: true
    },
    quantity: {
      type: [Number, String],
      required: true
    },
    configuration: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      itemQuantity: this.quantity,
      productUrl: `${this.$store.getters['gui/getBaseUrl'](this.productType.toLowerCase())}${this.product.urlSlug}`
    }
  },
  computed: {
    module () {
      return BASKET_MODULES.find(m => m.name === this.moduleName)
    }
  },
  methods: {
    updateItem () {
      this.$emit('basketitem:update', { id: this.basketItemId, quantity: this.itemQuantity })
    },
    configureItem () {
      // TODO: define action
    },
    deleteItem () {
      this.$emit('basketitem:delete', { id: this.basketItemId })
    }
  }
}
</script>

<style lang="scss">
// mobile

$basketitem-separator-gap-y: $spacer * 0.8 !default;
$basketitem-separator-border: map-get($borders, 'bold') !default;

$basketitem-image-width: 120px !default;
$basketitem-img-fallback-size: 100% !default;
$basketitem-img-fallback-color: $gray-300 !default;

$basketitem-description-name-font-weight: $font-weight-bold !default;
$basketitem-description-name-text-transform: uppercase !default;

$basketitem-configuration-gap-y: 0 !default;
$basketitem-configuration-gap-x: $spacer * 1.2 !default;

$basketitem-totalprice-font-weight: $font-weight-bold !default;

$basketitem-actions-gap-y: $spacer * 1 !default;
$basketitem-actions-gap-x: $basketitem-actions-gap-y !default;
$basketitem-actions-font-size: $font-size-base * 1.25 !default;

// tablet

$basketitem-tablet: $mobile-breakpoint !default;

$basketitem-tablet-image-width: 100px !default;

$basketitem-tablet-actions-gap-y: $spacer * 0.4 !default;
$basketitem-tablet-actions-gap-x: $spacer * 1.5 !default;
$basketitem-tablet-actions-font-size: 1rem !default;

.basket {
  .basket-items {
    .basket-item {
      display: contents;

      .area-separator {
        margin-top: $basketitem-separator-gap-y;
        margin-bottom: $basketitem-separator-gap-y;
        border-top: $basketitem-separator-border;
      }

      .area-image {
        a {
          display: block;
          color: inherit;
          text-decoration: none;

          img,
          .placeholder-image {
            display: block;
            width: $basketitem-image-width;
            max-width: none;

            .bi-image-placeholder {
              display: block;
              margin: auto;
              width: $basketitem-img-fallback-size;
              height: $basketitem-img-fallback-size;
              color: $basketitem-img-fallback-color;
            }
          }
        }
      }

      .area-description {
        a {
          display: block;
          color: inherit;
          text-decoration: none;

          .item-name {
            font-weight: $basketitem-description-name-font-weight;
            text-transform: $basketitem-description-name-text-transform;
          }
        }
      }

      .area-additional-configs{
        grid-column:start/5;
      }

      .area-configuration {
        .item-configuration {
          margin: ($basketitem-configuration-gap-y * -0.5) ( $basketitem-configuration-gap-x * -0.5);

          span {
            display: block;
            padding: ($basketitem-configuration-gap-y * 0.5) ($basketitem-configuration-gap-x * 0.5);
          }
        }
      }

      .area-actions {
        .item-actions {
          display: flex;
          flex-direction: column-reverse;
          margin: ($basketitem-actions-gap-y * -0.5) ($basketitem-actions-gap-x * -0.5);

          a {
            display: block;
            padding: ($basketitem-actions-gap-y * 0.5) ($basketitem-actions-gap-x * 0.5);
            font-size: $basketitem-actions-font-size;
          }
        }
      }

      .area-totalprice {
        font-weight: $basketitem-totalprice-font-weight;
      }

      + .basket-item {
        .area-separator {
          &:first-child {
            display: none;
          }
        }
      }

      @include media-breakpoint-up($basketitem-tablet) {
        .area-image {
          a {
            img,
            .placeholder-image {
              margin: 0;
              width: $basketitem-tablet-image-width;
            }
          }
        }

        .area-price,
        .area-totalprice {
          white-space: nowrap;
        }

        .area-actions {
          .item-actions {
            flex-direction: row;
            margin: ($basketitem-tablet-actions-gap-y * -0.5) ($basketitem-tablet-actions-gap-x * -0.5);

            a {
              padding: ($basketitem-tablet-actions-gap-y * 0.5) ($basketitem-tablet-actions-gap-x * 0.5);
              font-size: $basketitem-tablet-actions-font-size;
            }
          }
        }

        .area-additional-configs{
          grid-column:2 / span 5;
        }
      }
    }
  }
}
</style>
