<template>
  <c-leaf class="checkout-summary">
    <h6><slot name="title"/></h6>

    <ul class="summary-list" v-if="!is.initial">
      <component
        v-for="(cost, cIndex) in summary"
        :key="cIndex"
        :is="cost.component"
        :text="cost.label"
        :amount="cost.price"
        v-bind="cost.attrs"
      />
    </ul>

    <loader v-if="is.initial" variant="primary"/>
    <slot v-else name="content"/>
  </c-leaf>
</template>

<script>
import { COMPONENT_BASKET_MODULES, COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP, COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP } from '@/constants'

import Loader from '@/components/Loader'
import CheckoutSummaryPrice from '@/components/authenticated/Checkout/CheckoutSummaryPrice'
import CheckoutSummarySeparator from '@/components/authenticated/Checkout/CheckoutSummarySeparator'

const BASKET_MODULES = Object.values(COMPONENT_BASKET_MODULES)
const DEFAULT_MODULENAME = (BASKET_MODULES.find(m => m.isDefault) || BASKET_MODULES[0]).name
const ALLOWED_MODULE_NAMES = BASKET_MODULES.map(m => m.name)

export default {
  name: 'CheckoutSummary',
  components: {
    Loader,
    CheckoutSummaryPrice,
    CheckoutSummarySeparator
  },
  props: {
    moduleName: {
      type: String,
      default: DEFAULT_MODULENAME,
      validator: value => ALLOWED_MODULE_NAMES.includes(value)
    }
  },
  computed: {
    isDefaultModule () {
      return this.moduleName === DEFAULT_MODULENAME
    },
    module () {
      return BASKET_MODULES.find(m => m.name === this.moduleName)
    },
    is () {
      return this.$store.getters[`${this.module.name}/is`]
    },
    basket () {
      return this.$store.getters[`${this.module.name}/${this.module.getters.basket}`]
    },
    summary () {
      const priceDetails = this.basket.priceDetail || {}

      return []
        .concat(priceDetails.priceLines || [], {
          type: COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.total.type,
          label: priceDetails.totalPriceLabel || {},
          price: priceDetails.totalPrice
        })
        .map(p => Object.assign({}, p, {
          component: COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP[p.type].component,
          attrs: COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP[p.type].attrs,
          label: p.label[this.$store.getters['gui/getLanguage']]
        }))
        .reduce((summary, p) => summary.concat(p.type === COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.subtotal.type ? [COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.separator, p] : p), [])
    }
  }
}
</script>

<style lang="scss">
.checkout-summary {
  .summary-list {
    @include list-unstyled();

    > li {}
  }
}
</style>
