<template>
  <div class="basket">
    <h1 class="h4 mb-4">{{ $t(`${tPath}.title`) }}<span class="ml-4 h5 font-weight-normal text-dark">{{ $t(`${tPath}.subtitle`, { count: basket.itemCount }) }}</span></h1>

    <loader v-if="is.initial" variant="primary"/>

    <c-leaf v-else-if="basket.itemCount <= 0" class="basket-emptymessage">
      <b-row align-h="center" align-v="center">
        <b-col cols="auto">
          <div class="emptymessage-icon">
            <b-icon icon="cart" font-scale="8"/>
          </div>
        </b-col>

        <b-col :cols="!$root.isMobile ? 'auto' : 12">
          <div class="emptymessage-content">
            <slot name="emptymessage"/>
          </div>
        </b-col>
      </b-row>
    </c-leaf>

    <b-row v-else>
      <b-col lg="8" tag="article">
        <c-leaf class="basket-items">
          <div class="items-header">
            <div class="area-description">{{ $t(`${tPath}.items.header.description`) }}</div>
            <div class="area-quantity">{{ $t(`${tPath}.items.header.quantity`) }}</div>
            <div class="area-price">{{ $t(`${tPath}.items.header.price`) }}</div>
            <div class="area-totalprice">{{ $t(`${tPath}.items.header.totalprice`) }}</div>
          </div>

          <div class="items-main">
            <basket-item
              v-for="item in basket.items"
              :key="item.basketItemId"
              v-bind="item"
              :moduleName="moduleName"
              translationsOnce
              :translationsPath="tPath"
              @basketitem:update="$item => updateItem($item)"
              @basketitem:delete="$item => deleteItem($item)"
            />
          </div>

          <div v-if="isDefaultModule || module.name=='checkout'" class="items-footer">
            <div class="area-basketactions-start" v-if="isDefaultModule">
              <div class="footer-actions">
                <b-link @click="deleteBasket"><b-icon icon="trash"/> {{ $t(`${tPath}.items.footer.action.deletebasket`) }}</b-link>
              </div>
            </div>

            <div class="area-basketactions-end" v-if="isDefaultModule || module.name=='checkout'">
              <div class="footer-actions">
                <b-link @click="printBasket"><b-icon icon="printer"/> {{ $t(`${tPath}.items.footer.action.print`) }}</b-link>
              </div>
            </div>
          </div>
        </c-leaf>
        <slot name="afterbasketcontent"/>
      </b-col>

      <b-col lg="4" tag="aside" class="mt-3 mt-lg-0">
        <checkout-summary :moduleName="moduleName">
          <template #title>
            {{ $t(`${tPath}.summary.title`) }}
          </template>

          <template #content>
            <slot name="summaryfooter"/>
          </template>
        </checkout-summary>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { COMPONENT_BASKET_MODULES } from '@/constants'

import Loader from '@/components/Loader'
import BasketItem from '@/components/BasketItem'
import CheckoutSummary from '@/components/authenticated/Checkout/CheckoutSummary'

const BASKET_MODULES = Object.values(COMPONENT_BASKET_MODULES)
const DEFAULT_MODULENAME = (BASKET_MODULES.find(m => m.isDefault) || BASKET_MODULES[0]).name
const ALLOWED_MODULE_NAMES = BASKET_MODULES.map(m => m.name)

export default {
  name: 'Basket',
  components: {
    Loader,
    BasketItem,
    CheckoutSummary
  },
  props: {
    moduleName: {
      type: String,
      default: DEFAULT_MODULENAME,
      validator: value => ALLOWED_MODULE_NAMES.includes(value)
    }
  },
  computed: {
    isDefaultModule () {
      return this.moduleName === DEFAULT_MODULENAME
    },
    module () {
      return BASKET_MODULES.find(m => m.name === this.moduleName)
    },
    is () {
      return this.$store.getters[`${this.module.name}/is`]
    },
    basket () {
      return this.$store.getters[`${this.module.name}/${this.module.getters.basket}`]
    }
  },
  methods: {
    printBasket () {
      window.print()
    },
    deleteBasket () {
      this.$store.dispatch('shoppingcart/delete')
    },
    updateItem (item) {
      this.$store.dispatch('shoppingcart/updateItem', { item, omitLastAction: true })
    },
    deleteItem (item) {
      this.$store.dispatch('shoppingcart/deleteItem', { item, omitLastAction: true })
    },
    beforePrint () {
      document.documentElement.classList.add('basket-print-mode')
    },
    afterPrint () {
      document.documentElement.classList.remove('basket-print-mode')
    }
  },
  created () {
    this.$store.dispatch(`${this.module.name}/${this.module.actions.get}`)

    window.addEventListener('beforeprint', this.beforePrint)
    window.addEventListener('afterprint', this.afterPrint)
  },
  beforeDestroy () {
    window.removeEventListener('beforeprint', this.beforePrint)
    window.removeEventListener('afterprint', this.afterPrint)
  }
}
</script>

<style lang="scss">
// mobile

$basket-emptymessage-icon-gap: $spacer !default;
$basket-emptymessage-content-text-align: center !default;

$basket-items-grid-column-gap: $grid-gutter-width * 0.5 !default;
$basket-items-grid-row-gap: 0 !default;
$basket-items-grid-columns: [start] auto 4fr 2fr 2fr 1.5rem [end] !default;
$basket-items-grid-rows: min-content auto min-content !default;
$basket-items-grid-area-gap-y: $spacer !default;

$basket-items-footer-actions-gap-y: $spacer * 0.5 !default;
$basket-items-footer-actions-gap-x: $spacer * 2 !default;

// tablet

$basket-tablet: $mobile-breakpoint !default;

$basket-tablet-emptymessage-icon-gap: 0 !default;
$basket-tablet-emptymessage-content-text-align: inherit !default;

$basket-tablet-items-grid-columns: [start] auto 4fr 1fr 1fr 1fr [end] !default;

$basket-tablet-items-header-font-size: $font-size-sm !default;
$basket-tablet-items-header-color: $dark !default;
$basket-tablet-items-header-line-height: 1.1 !default;

// desktop

$basket-desktop: $desktop-breakpoint !default;

.basket {
  @include component('y');

  .basket-emptymessage {
    @include component('y');

    .emptymessage-icon {
      margin-bottom: $basket-emptymessage-icon-gap;
    }

    .emptymessage-content {
      text-align: $basket-emptymessage-content-text-align;
    }
  }

  .basket-items {
    display: grid;
    column-gap: $basket-items-grid-column-gap;
    row-gap: $basket-items-grid-row-gap;
    grid-template-columns: $basket-items-grid-columns;
    grid-template-rows: $basket-items-grid-rows;

    [class*="area-"] {
      grid-row: auto;
    }

    .area-image,
    .area-configuration,
    .area-quantity {
      margin-bottom: $basket-items-grid-area-gap-y;
    }

    .area-separator {
      grid-column: start / end;
      justify-self: stretch;
    }

    .area-image {
      grid-column: start / 5;
      justify-self: stretch;
    }

    .area-description {
      grid-column: start / 5;
    }

    .area-actions {
      grid-column: 5 / end;
      justify-self: flex-end;
    }

    .area-configuration {
      grid-column: start / 5;
    }

    .area-quantity {
      grid-column: start / 5;
      justify-self: stretch;
    }

    .area-price {
      grid-column: start / 5;
      justify-self: stretch;
    }

    .area-totalprice {
      grid-column: start / 5;
      justify-self: stretch;
    }

    .items-header {
      display: none;
    }

    .items-main {
      display: contents;
    }

    .items-footer {
      display: contents;

      .area-basketactions-start {
        grid-column: start / end;
      }

      .area-basketactions-end {
        margin-top: $basket-items-footer-actions-gap-y;
        grid-column: start / end;
      }

      .footer-actions {
        margin: ($basket-items-footer-actions-gap-y * -0.5) ($basket-items-footer-actions-gap-x * -0.5);

        a {
          display: block;
          padding: ($basket-items-footer-actions-gap-y * 0.5) ($basket-items-footer-actions-gap-x * 0.5);
        }
      }
    }
  }

  @include media-breakpoint-up($basket-tablet) {
    .basket-emptymessage {
      .emptymessage-icon {
        margin-bottom: $basket-tablet-emptymessage-icon-gap;
      }

      .emptymessage-content {
        text-align: $basket-tablet-emptymessage-content-text-align;
      }
    }

    .basket-items {
      grid-template-columns: $basket-tablet-items-grid-columns;

      .area-image,
      .area-description,
      .area-configuration,
      .area-quantity {
        margin-bottom: 0;
      }

      .area-image {
        grid-column: start;
        grid-row: auto / span 2;
      }

      .area-description {
        grid-column: 2;
      }

      .area-quantity {
        grid-column: 3;
        justify-self: center;
      }

      .area-price {
        grid-column: 4;
        justify-self: flex-end;
      }

      .area-totalprice {
        grid-column: 5;
        justify-self: flex-end;
      }

      .area-configuration {
        grid-column: 2;
        align-self: center;
      }

      .area-actions {
        align-self: flex-end;
      }

      .items-header {
        display: contents;
        font-size: $basket-tablet-items-header-font-size;
        color: $basket-tablet-items-header-color;
        line-height: $basket-tablet-items-header-line-height;

        .area-description {
          grid-column: start / 3;
        }
      }

      .items-footer {
        .area-basketactions-start {
          grid-column: start / 4;
        }

        .area-basketactions-end {
          grid-column: 4 / end;
          justify-self: flex-end;
          margin-top: 0;
        }

        .footer-actions {
          display: flex;
        }
      }
    }
  }
}

@media print {
  .basket-print-mode {
    #app {
      > header,
      > footer {
        display: none!important;
      }

      > main {
        .basket ~ * {
          display: none!important;
        }
      }
    }
  }
}
</style>
